.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
::-webkit-scrollbar {
  display: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.custom-select {
  position: relative;
  display: inline-block;
}

.select-control {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-control.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.selected-option {
  margin-right: 10px;
}

.dropdown-icon {
  display: flex;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
}

.search-box {
  padding: 10px;
}

.search-box input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.options {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.option {
  padding: 10px;
  cursor: pointer;
}

.option:hover {
  background-color: #f1f1f1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
